import { useContext, useEffect, useState } from 'react';
import '../sass/notmain.scss';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {makeRequest} from '../axios.js';
import { AuthContext } from '../context/authContext';

const Otp = () => {
    const navigate = useNavigate();
    const {login} = useContext(AuthContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get("email");

    const [error,setError] = useState('');
    const [input,setInput] = useState({
        email:email,
        otp:''
    });
    const resendOtp = async(e) => {
        e.preventDefault();
        try {
            const resultOtp = await makeRequest.get('generate-otp');

            //send email OTP
            const emailDto = {
                from:process.env.REACT_APP_SMTP_EMAIL_FROM,
                to:input.email,
                subject:'Kirim ulang OTP untuk akun '+input.email,
                otp:resultOtp.data.data.otp,
                type:'resend-otp'
            };
            await makeRequest.post('send-email',emailDto);

            await makeRequest.put('users/update-otp?email='+input.email,{otp:resultOtp.data.data.otp});
        } catch (error) {
            setError(error.response.data.message);
        }
    }
    const verify = async(e) => {
        e.preventDefault();
        try {
            await makeRequest.post('users/verify-otp',input);
            const result = await makeRequest.get('users/find-by-user-email?email='+input.email);
            const resultData = result.data.data;
            login({email:resultData.email,password:resultData.password});
            navigate('/');
        } catch (error) {
            setError(error.response.data.message);
        }
    }
    useEffect(()=>{
    },[error])
    return (
        <div className='forgot-password'>
            <div className="wrapper">
                <img src="https://cdn-icons-png.flaticon.com/512/6897/6897039.png" />
                <div className='title'>Lupa Password</div>
                <div className='detail'>Untuk melanjutkan registrasi, agar anda terdaftar di sistem kami, mohon masukkan kode OTP yang telah kami kirim ke email Anda.</div>
                <input id="otp" name="otp" className='input' onChange={(e)=>setInput(prev=>({...prev,otp:e.target.value}))} placeholder='OTP' style={{padding:'10px 20px',borderRadius:'5px',fontSize:'14px'}}/>
                {error && <div className='error'>{error}</div>}
                <button className='btn-submit' onClick={verify}>Verifikasi</button>
                <button className='btn-resend' onClick={resendOtp}>Kirim Ulang OTP</button>
                <Link to="/" className='back-to-login'>Kembali ke Beranda</Link>
            </div>
        </div>
    )
}

export default Otp