import {format} from 'date-fns';

export const convertDate = (formatDate,value) => {
    let date = new Date(value)
    let result = format(date,formatDate);
    return result;
}

export const FOLDER_MEDIA_PATH = process.env.REACT_APP_API_URL+'/images/';

export const thousandSeparator = (value) => {
    return value.toLocaleString('id-ID', {minimumFractionDigits: 0, maximumFractionDigits: 2});
}