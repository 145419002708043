import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TopMenu from './TopMenu.jsx';
import MobileWrapper from './MobileWrapper.jsx';
import { AuthContext } from '../context/authContext.js';
import { makeRequest } from '../axios.js';
import MobileMenuList from './MobileMenuList.jsx';
import { ToastContainer, toast } from 'react-toastify';
import {FOLDER_MEDIA_PATH,thousandSeparator} from '../utils.js';
import 'react-toastify/dist/ReactToastify.css';

const HeaderMainContainer = ({activeLinkData,onUpdate,loginClicked,setLoginClicked}) => {
    const navigate = useNavigate();
    //context
    const {login,logout,currentUser,register} = useContext(AuthContext);

    //state
    //cart
    const [cartDatas,setCartDatas] = useState([]);
    const [dataOrder,setDataOrder] = useState(0);
    const [cartCount,setCartCount] = useState(0);
    //notifications
    const [notificationDatas,setNotificationDatas] = useState([]);
    const [notificationCount,setNotificationCount] = useState(0);

    const [activeLink,setActiveLink] = useState(activeLinkData);
    const [inputs,setInputs] = useState({email:'',password:''});
    const [emailLabelActive,setEmailLabelActive] = useState('');
    const [emailFilled,setEmailFilled] = useState('');
    const [passwordLabelActive,setPasswordLabelActive] = useState('');
    const [passwordFilled,setPasswordFilled] = useState('');
    const [inputRegister,setInputRegister] = useState({username:'',email:'',password:''});
    const [usernameRegisterLabelActive,setUsernameRegisterLabelActive] = useState('');
    const [usernameRegisterFilled,setUsernameRegisterFilled] = useState('');
    const [emailRegisterLabelActive,setEmailRegisterLabelActive] = useState('');
    const [emailRegisterFilled,setEmailRegisterFilled] = useState('');
    const [passwordRegisterLabelActive,setPasswordRegisterLabelActive] = useState('');
    const [passwordRegisterFilled,setPasswordRegisterFilled] = useState('');
    const [countCart,setCountCart] = useState(0);
    const [dropdownCart,setDropdownCart] = useState(false);
    const [dropdownUsermenu,setDropdownUsermenu] = useState(false);
    const [dropdownNotification,setDropdownNotification] = useState(false);
    const [stateLogin,setStateLogin] = useState(true);
    const [bgOverlay,setBgOverlay] = useState(false);

    const [menu,setMenu] = useState(window.location.pathname == '/' ? 'beranda' : window.location.pathname == '/katalog' || window.location.pathname == '/brosur' ? 'unduh' : window.location.pathname.substring(1));

    //ref
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const dropdownCartRef = useRef();
    const dropdownNotificationRef = useRef();
    const dropdownUsermenuRef = useRef(null);
    const usernameRegisterRef = useRef(null);
    const emailRegisterRef = useRef(null);
    const passwordRegisterRef = useRef(null);
    const windowWidth = useRef(window.innerWidth);
    let location = useLocation();

    const onUpdateMenu = (e,value) => {
        e.preventDefault();
        setMenu(value)
    }
    const handleClick = (e,value,type) => {
        e.preventDefault()
        setActiveLink(value);
        setBgOverlay(value!=='');
        if(type==='cart'){
            navigate('/profil',{state:{widget:'keranjang'}});
        } else if(type==='notification'){
            navigate('/profil',{state:{widget:'notifikasi'}});
            showOrHideDropdownNotification(e);
        }
    }
    const handleUpdateActiveLink = (e,value) => {
        e.preventDefault();
        setLoginClicked(false);
        setDropdownNotification(false);
        setDropdownUsermenu(false);
        setActiveLink(value);
        if(value!=='notification'){
            if(value!=='' && !currentUser){
                if(value.includes('mobile')){
                   setActiveLink(value);
                } else {
                   setActiveLink('usermenu');
                }
            }
            setBgOverlay(value!=='');
        } else {
            if(!currentUser){
                if(windowWidth.current>980){
                    setActiveLink('usermenu');
                } else {
                    setActiveLink('mobile-usermenu');
                }
                setBgOverlay(value!=='');
            } else {
                showOrHideDropdownNotification(e);
            }
        }
    }
    const closePopupCart = (e) => {
        e.preventDefault();
        if(!dropdownCartRef.current.contains(e.target)){
            setDropdownCart(false);
        }
    }
    const closePopupNotification = (e) => {
        e.preventDefault();
        if(dropdownNotificationRef.current && !dropdownNotificationRef.current.contains(e.target)){
            setDropdownNotification(false);
        }
    }
    const closePopupUsermenu = (e) => {
        e.preventDefault();
        if(dropdownUsermenuRef.current && !dropdownUsermenuRef.current.contains(e.target)){
            setDropdownUsermenu(false);
        }
    }
    const initCartDatas = async() => {
        if(currentUser){
            try {
                const result = await makeRequest.get('carts/find-all');
                const data = result.data.data;
                var totalValue = data.reduce(function(total, arr) {
                    return total + arr.price;
                },0);

                setCartDatas(data);
                setCartCount(data.length);
                setDataOrder(totalValue);
            } catch (error) {
                setCartDatas([]);
                setCartCount(0);
                setDataOrder(0);
                if(error.response.status===403 || error.response.status===401){
                    logout();
                    alert(error.response.data.message);
                }
            }
        } else {
            setCartDatas([]);
            setCartCount(0);
            setDataOrder(0);
        }
    }

    const initNotificationDatas = async() => {
        try {
            if(currentUser) {
                const result = await makeRequest.get("notifications/find-by-userId-and-unread?userId="+currentUser.id);
                setNotificationDatas(result.data.data);
                setNotificationCount(result.data.data.length);
            } else {
                setNotificationDatas([]);
                setNotificationCount(0);
            }
        } catch(err){
            setNotificationDatas([]);
            setNotificationCount(0);
        }
    }
    useLayoutEffect((e)=>{
        initCartDatas();
        initNotificationDatas();

        if(loginClicked){
            setActiveLink('usermenu');
            setBgOverlay('usermenu');
        }

        if(activeLink === 'cart'){
            document.addEventListener("mousedown", closePopupCart);
        }
        if(activeLink === 'notification'){
            document.addEventListener("mousedown", closePopupNotification);
        }
        if(activeLink === 'usermenu'){
            document.addEventListener("mousedown", closePopupUsermenu);
        }
        return () => {
            if(activeLink === 'cart'){
                document.removeEventListener("mousedown", closePopupCart);
            }
            if(activeLink === 'notification'){
                document.removeEventListener("mousedown", closePopupNotification);
            }
            if(activeLink === 'usermenu'){
                document.removeEventListener("mousedown", closePopupUsermenu);
            }
        };
    },[activeLink]);

    const removeItem = async (e,data) => {
        e.preventDefault();
        try {
            await makeRequest.delete('carts?id='+data.id);
            callToastNotification('hapus produk dari keranjang berhasil!','success');
        } catch (error) {
            callToastNotification('hapus produk dari keranjang gagal!','error');
        }
        initCartDatas();
    }
    const handleChange = (e,type) => {
        e.preventDefault();
        if(type==='email'){
            setInputs((prev) => ({...prev, email:e.target.value}));
        } else {
            setInputs((prev) => ({...prev, password:e.target.value}));
        }
    }
    const handleFocus = (e,type) => {
        e.preventDefault();
        if(type==='email'){
            emailRef.current.focus();
            setEmailLabelActive('active');
        } else {
            passwordRef.current.focus();
            setPasswordLabelActive('active');
        }
    }
    const handleBlur = (e,type) => {
        const {value} = e.target;
        if(type==='email'){
            emailRef.current.blur();
            if(value!==''){
                setEmailFilled('filled')
            } else {
                setEmailLabelActive('');
                setEmailFilled('');
            }
        } else {
            passwordRef.current.blur();
            if(value!==''){
                setPasswordFilled('filled')
            } else {
                setPasswordLabelActive('');
                setPasswordFilled('');
            }
        }
    }
    const handleRegisterChange = (e,type) => {
        e.preventDefault();
        if(type==='username'){
            setInputRegister((prev) => ({...prev, username:e.target.value}));
        } else if(type==='email'){
            setInputRegister((prev) => ({...prev, email:e.target.value}));
        } else if(type==='password'){
            setInputRegister((prev) => ({...prev, password:e.target.value}));
        }
    }
    const handleRegisterFocus = (e,type) => {
        e.preventDefault();
        if(type==='username'){
            usernameRegisterRef.current.focus();
            setUsernameRegisterLabelActive('active');
        } if(type==='email'){
            emailRegisterRef.current.focus();
            setEmailRegisterLabelActive('active');
        } else if(type==='password'){
            passwordRegisterRef.current.focus();
            setPasswordRegisterLabelActive('active');
        }
    }
    const handleRegisterBlur = (e,type) => {
        const {value} = e.target;
        if(type==='username'){
            usernameRegisterRef.current.blur();
            if(value!==''){
                setUsernameRegisterFilled('filled')
            } else {
                setUsernameRegisterLabelActive('');
                setUsernameRegisterFilled('');
            }
        } else if(type==='email'){
            emailRegisterRef.current.blur();
            if(value!==''){
                setEmailRegisterFilled('filled')
            } else {
                setEmailRegisterLabelActive('');
                setEmailRegisterFilled('');
            }
        } else if(type==='password'){
            passwordRegisterRef.current.blur();
            if(value!==''){
                setPasswordRegisterFilled('filled')
            } else {
                setPasswordRegisterLabelActive('');
                setPasswordRegisterFilled('');
            }
        }
    }
    const handleLogin = async(e) => {
        e.preventDefault();
        const result = await login(inputs);
        if(result.status === 'ERROR'){
            if(result.message.includes('verifikasi')){
                navigate('/verifikasi-otp?email='+inputs.email);
            }
            callToastNotification(result.message,'error');
        } else {
            setInputs({email:'',password:''});
            setActiveLink('');
            onUpdate('');
            setLoginClicked(false);
            setBgOverlay(false);
            callToastNotification(result.message,'success');
        }
    }
    const handleRegister = async e => {
        e.preventDefault();
        const result = await register(inputRegister);
        if(result.status==='SUCCESS'){
            //generate otp
            const resultOtp = await makeRequest.get('generate-otp');
            await makeRequest.put('users/update-otp?email='+inputRegister.email,{otp:resultOtp.data.data.otp});

            //send email verify account
            const emailDto = {
                from:process.env.REACT_APP_SMTP_EMAIL_FROM,
                to:inputRegister.email,
                subject:'Verifikasi akun '+inputRegister.email,
                otp:resultOtp.data.data.otp,
                type:'verification'
            };
            await makeRequest.post('send-email',emailDto);

            setInputRegister({username:'',email:'',password:''});
            setActiveLink('');
            setLoginClicked(false);
            onUpdate('');
            setBgOverlay(false);
            navigate('/verifikasi-otp?email='+inputRegister.email)
            setInputRegister({username:'',email:'',password:''});
            callToastNotification('Daftar akun berhasil! Mohon untuk cek alamat email anda, untuk melanjutkan proses verifikasi.','success');
        } else {
            callToastNotification(result.message,'error');
        }
    }
    const showOrHideDropdownUsermenu = e => {
        e.preventDefault();
        setDropdownUsermenu(!dropdownUsermenu);
        setDropdownNotification(false);
    }
    const showOrHideDropdownNotification = e => {
        e.preventDefault();
        setDropdownNotification(!dropdownNotification);
        setDropdownUsermenu(false);
    }
    const handleLogout = async e => {
        e.preventDefault();
        try {
            await logout();
            navigate("/");
            setActiveLink('');
            setLoginClicked(false);
            onUpdate('');
            setBgOverlay(false);
            callToastNotification('success log out!','success');
        } catch(err){
            callToastNotification('log out error!','error');
        }
        window.location.reload();
    };
    const changeStateLogin = (e,value) => {
        e.preventDefault();
        setStateLogin(value);
    }
    const callToastNotification = (message,type) => {
        if(type === 'success'){
            toast.success(message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else if(type === 'error'){
            toast.error(message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    useEffect(()=>{
        // if(!currentUser){
        //     if(windowWidth.current>980){
        //         setActiveLink('usermenu');
        //         setBgOverlay(true);
        //     } else {
        //         setActiveLink('mobile-usermenu');
        //         setBgOverlay(true);
        //     }
        // }
    },[]);
    const changeBgOverlay = (e,value) => {
        e.preventDefault();
        setBgOverlay(value);
    }
    const goTo = (e) => {
        onUpdateMenu(e,'beranda')
        navigate("/");
    }
    const usermenuClicked = (path) => {
        setDropdownUsermenu(false);
        setDropdownNotification(false);
        navigate(path);
    }
    // const handleClickNotification = (data) => {
    //     if(data.type ==='news'){
    //         navigate('/detail-berita/'+data.pathId)
    //     }
    // }
    const checkout = async(e,datas) => {
        e.preventDefault();
        if(!currentUser){
            setLoginClicked(true);
        } else {
            try {
                let total = 0;
                datas.map(data=>total+=data.price);
                //save to order
                const order = await makeRequest.post('orders',{
                    total:total,
                    status:'UNPAID',
                    userId:currentUser.id,
                });

                for(let i=0;i<datas.length;i++){
                    const colorObj = datas[i].product_color;

                    //save to order_items
                    await makeRequest.post('order-items',{
                        orderId: order.data.data.id,
                        userId: currentUser.id,
                        productColorId: colorObj.id,
                        productId: datas[i].productId,
                        quantity: datas[i].quantity,
                        price: parseInt(datas[i].price)
                    });

                    const userAddress = await makeRequest.get('user-address/find-all?userId='+currentUser.id);
                    await makeRequest.post('order-shipment',{
                        orderId: order.data.data.id,
                        shipmentId: 17,
                        userAddressId: userAddress.data.data[0].id,
                        price: 12000,
                    });
                }

                navigate("/pembayaran/"+order.data.data.id);
            } catch (error) {
                console.log('Servis sedang mengalami gangguan, dimohon untuk mencoba kembali lagi nanti!',error);
            }
        }
    }
  return (
    <>
        <ToastContainer/>
        <header>
            <div className="container-header">
                <div className="logo-container">
                    <img src={FOLDER_MEDIA_PATH+"LOGO.png"} className="main-logo" alt="logoImage" onClick={goTo}/>
                </div>
                <TopMenu menu={menu} onUpdateMenu={onUpdateMenu}/>
                <ul className="utility-menu">
                    {/* start of search */}
                    <li>
                        <div className="search-top-menu">
                            <div className="form-group">
                                <label htmlFor="search">Search:</label>
                                <input type="text" autoComplete="off" name="search" id="search" className="form-control search-top" required/>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a role="button" className="js-search-top">
                            <svg viewBox="0 0 400 400"><g transform="matrix(1.3333333,0,0,-1.3333333,0,400)" id="g10"><g transform="scale(0.1)" id="g12"><path id="path14" d="m 1312.7,795.5 c -472.7,0 -857.204,384.3 -857.204,856.7 0,472.7 384.504,857.2 857.204,857.2 472.7,0 857.3,-384.5 857.3,-857.2 0,-472.4 -384.6,-856.7 -857.3,-856.7 z M 2783.9,352.699 2172.7,963.898 c 155.8,194.702 241.5,438.602 241.5,688.302 0,607.3 -494.1,1101.4 -1101.5,1101.4 -607.302,0 -1101.399,-494.1 -1101.399,-1101.4 0,-607.4 494.097,-1101.501 1101.399,-1101.501 249.8,0 493.5,85.5 687.7,241 L 2611.7,181 c 23,-23 53.6,-35.699 86.1,-35.699 32.4,0 63,12.699 86,35.699 23.1,22.801 35.8,53.301 35.8,85.898 0,32.602 -12.7,63 -35.7,85.801"></path></g></g></svg>
                        </a>
                    </li>
                    {/* end of search */}
                    <li className="li-notif">
                        <a role="button" className="js-dropdown-notif" onClick={(e) => handleUpdateActiveLink(e,'notification')}>
                            <svg viewBox="0 0 1080 1080"><path className="cls-1" d="M1004.41,816.56H75.59V778.81c0-68.33,6.64-128.2,81.67-163.35v-122C157.26,338.62,259,200,408.09,147.19v-19c0-38.82,17.8-75.12,48.83-99.59C489.37,3,531.7-6,573,3.94c57.3,13.74,98.88,67.54,98.88,127.93v15.33C821,200,922.74,338.62,922.74,493.51V615.3c81.67,36.69,81.67,92.63,81.67,161.85Zm-852-75.49H928.44c-.66-14.37-2.24-25.55-6-33.37S909.6,689.19,873,676.88l-25.72-8.65V493.51c0-130.39-91.69-246.43-223-282.19l-27.83-7.58V131.87c0-25.89-17.24-48.82-41-54.52-18.72-4.49-37.59-.65-51.77,10.54C490.9,98,483.58,112.65,483.58,128.2v75.55l-27.83,7.58c-131.29,35.76-223,151.8-223,282.19V668.36l-25.89,8.57C168.86,689.51,156.06,701.09,152.38,741.07Z"/><path className="cls-1" d="M540,1080A122.3,122.3,0,1,1,662.3,957.7,122.44,122.44,0,0,1,540,1080Zm0-169.1a46.8,46.8,0,1,0,46.8,46.8A46.85,46.85,0,0,0,540,910.9Z"/></svg>
                            {notificationCount > 0 ? <span className="js-number-cart number-cart active">{notificationCount}</span> : <span className='js-number-cart number-notif'></span>}
                        </a>
                        <div ref={dropdownNotificationRef} className="dropdown-notification" style={{display : dropdownNotification ? 'block':'none'}}>
                            <ul className="dropdown-notif-list">
                                {notificationDatas.length > 0 && notificationDatas.map(data=>{
                                    return (
                                        <li className="dropdown-notif-item" key={data.id}>
                                            <a>
                                                {data.type === 'order' && data?.order?.order_items?.map(item=>{
                                                    return (
                                                        <div className="row-notif-product" key={item.id}>
                                                            <div className="notif-foto">
                                                                <img src={FOLDER_MEDIA_PATH+item.product_color.product_color_images[0].file.name} alt="productImage" />
                                                            </div>
                                                            <div className="notif-desc">
                                                                <div className="notif-product-name">{data.notificationMessage}</div>
                                                                <div className="notif-no-pesanan">{data.order.id} (Nomor pesanan)</div>
                                                                <div className="notif-date">{data.order.createdAt}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {data.type !== 'order' &&
                                                    <div className="row-notif-border unread">{data.notificationMessage}</div>
                                                }
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                            {notificationDatas.length > 0 ? (
                                <div className="text-center pv5px">
                                    <a onClick={(e) => handleClick(e,'','notification')} className="green fz12 dropdown-notif-item">Lihat Selengkapnya</a>
                                </div>
                            ) : (<div className="text-center pv5px">
                                    <div>Tidak ada notifikasi</div>
                                </div>)}
                        </div>
                    </li>
                    <li>
                        <a className="js-show-cart" onClick={(e) => handleUpdateActiveLink(e,'cart')}>
                            <svg viewBox="0 0 260.293 260.293">
                                <g>
                                    <path d="M258.727,57.459c-1.42-1.837-3.612-2.913-5.934-2.913H62.004l-8.333-32.055c-0.859-3.306-3.843-5.613-7.259-5.613H7.5
                                        c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h33.112l8.333,32.055c0,0.001,0,0.001,0.001,0.002l29.381,112.969
                                        c0.859,3.305,3.843,5.612,7.258,5.612h137.822c3.415,0,6.399-2.307,7.258-5.612l29.385-112.971
                                        C260.636,61.687,260.147,59.295,258.727,57.459z M117.877,167.517H91.385l-5.892-22.652h32.384V167.517z M117.877,129.864H81.592
                                        l-5.895-22.667h42.18V129.864z M117.877,92.197H71.795l-5.891-22.651h51.973V92.197z M176.119,167.517h-43.242v-22.652h43.242
                                        V167.517z M176.119,129.864h-43.242v-22.667h43.242V129.864z M176.119,92.197h-43.242V69.546h43.242V92.197z M217.609,167.517
                                        h-26.49v-22.652h32.382L217.609,167.517z M227.403,129.864h-36.284v-22.667h42.18L227.403,129.864z M237.201,92.197h-46.081V69.546
                                        h51.974L237.201,92.197z"/>
                                    <path d="M105.482,188.62c-15.106,0-27.396,12.29-27.396,27.395c0,15.108,12.29,27.4,27.396,27.4
                                        c15.105,0,27.395-12.292,27.395-27.4C132.877,200.91,120.588,188.62,105.482,188.62z M105.482,228.415
                                        c-6.835,0-12.396-5.563-12.396-12.4c0-6.835,5.561-12.395,12.396-12.395c6.834,0,12.395,5.561,12.395,12.395
                                        C117.877,222.853,112.317,228.415,105.482,228.415z"/>
                                    <path d="M203.512,188.62c-15.104,0-27.392,12.29-27.392,27.395c0,15.108,12.288,27.4,27.392,27.4
                                        c15.107,0,27.396-12.292,27.396-27.4C230.908,200.91,218.618,188.62,203.512,188.62z M203.512,228.415
                                        c-6.833,0-12.392-5.563-12.392-12.4c0-6.835,5.559-12.395,12.392-12.395c6.836,0,12.396,5.561,12.396,12.395
                                        C215.908,222.853,210.347,228.415,203.512,228.415z"/>
                                </g>
                            </svg>
                            {cartCount > 0 ? <span className="js-number-cart number-cart active">{cartCount}</span> : <span className="js-number-cart number-cart"></span>}
                        </a>
                    </li>
                    {!currentUser &&
                        <li className="li-login-user">
                            <a role="button" className="js-show-usermenu" onClick={(e) => handleUpdateActiveLink(e,'usermenu')}>
                                <svg viewBox="0 0 1080 1080"><path className="cls-1" d="M538.56,469.28c-89.57,0-162.44-72.86-162.44-162.41S449,144.46,538.56,144.46s162.38,72.86,162.38,162.41S628.1,469.28,538.56,469.28Zm0-245.17a82.76,82.76,0,1,0,82.73,82.75A82.86,82.86,0,0,0,538.56,224.12Z"/><path className="cls-1" d="M760.52,912.32h-441a49.81,49.81,0,0,1-49.77-49.74V724c0-127.86,104-231.89,231.92-231.89h76.72c127.88,0,231.92,104,231.92,231.89V862.58A49.8,49.8,0,0,1,760.52,912.32ZM349.37,832.66H730.62V724c0-83.94-68.3-152.23-152.26-152.23H501.64c-84,0-152.26,68.29-152.26,152.23Z"/><path className="cls-1" d="M540,1080C242.24,1080,0,837.76,0,540S242.24,0,540,0s540,242.24,540,540S837.76,1080,540,1080ZM540,79.65C286.16,79.65,79.65,286.16,79.65,540S286.16,1000.35,540,1000.35,1000.35,793.84,1000.35,540,793.84,79.65,540,79.65Z"/></svg>
                            </a>
                        </li>
                    }
                    {currentUser &&
                        <li className="li-after-login" style={{display: currentUser ? 'block' : 'none' }}>
                            <div className="user-login-wrapper js-dropdown-user" onClick={showOrHideDropdownUsermenu}>
                                <img src={currentUser.imageName ? FOLDER_MEDIA_PATH+currentUser.imageName : FOLDER_MEDIA_PATH+'ICON-USER.png'} alt="profileImage" />
                            </div>
                            <div className="dropdown-user-menu" ref={dropdownUsermenuRef} style={{display : dropdownUsermenu ? 'block':'none'}}>
                                <div className="dropdown-user-header">
                                    <div className="user-photo-dropdown">
                                        <img src={currentUser.imageName ? FOLDER_MEDIA_PATH+currentUser.imageName : FOLDER_MEDIA_PATH+'ICON-USER.png'} alt="profileImage" />
                                    </div>
                                    <div className="user-info-dropdown">
                                        <div className="user-info-name">{currentUser.name ? currentUser.name : currentUser.email}</div>
                                        <div className="user-info-status">
                                            <svg viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.8613 9.36335L10.7302 9.59849C10.5862 9.85677 10.5142 9.98591 10.402 10.0711C10.2897 10.1563 10.1499 10.188 9.87035 10.2512L9.61581 10.3088C8.63195 10.5314 8.14001 10.6427 8.02297 11.0191C7.90593 11.3955 8.2413 11.7876 8.91204 12.572L9.08557 12.7749C9.27617 12.9978 9.37147 13.1092 9.41435 13.2471C9.45722 13.385 9.44281 13.5336 9.41399 13.831L9.38776 14.1018C9.28635 15.1482 9.23565 15.6715 9.54206 15.9041C9.84847 16.1367 10.3091 15.9246 11.2303 15.5005L11.4686 15.3907C11.7304 15.2702 11.8613 15.2099 12 15.2099C12.1387 15.2099 12.2696 15.2702 12.5314 15.3907L12.7697 15.5005C13.6909 15.9246 14.1515 16.1367 14.4579 15.9041C14.7644 15.6715 14.7136 15.1482 14.6122 14.1018L14.586 13.831C14.5572 13.5336 14.5428 13.385 14.5857 13.2471C14.6285 13.1092 14.7238 12.9978 14.9144 12.7749L15.088 12.572C15.7587 11.7876 16.0941 11.3955 15.977 11.0191C15.86 10.6427 15.3681 10.5314 14.3842 10.3088L14.1296 10.2512C13.8501 10.188 13.7103 10.1563 13.598 10.0711C13.4858 9.98592 13.4138 9.85678 13.2698 9.5985L13.1387 9.36335C12.6321 8.45445 12.3787 8 12 8C11.6213 8 11.3679 8.45446 10.8613 9.36335Z" />
                                            </svg>
                                            <div>Member Nine Autoseries</div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="dropdown-user-list">
                                    <li className="dropdown-user-item">
                                        <a role="button" onClick={()=>usermenuClicked('/profil')}>Halaman Saya</a>
                                    </li>
                                    <li className="dropdown-user-item">
                                        <a role="button" onClick={()=>usermenuClicked('/ubah-profil')}>Ganti Profil</a>
                                    </li>
                                    <li className="dropdown-user-item">
                                        <a role="button" onClick={()=>usermenuClicked('/daftar-alamat')}>Daftar Alamat</a>
                                    </li>
                                    <li className="dropdown-user-item js-logout-user" onClick={handleLogout}>
                                        <a href="#">Keluar</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    }
                </ul>
                <MobileWrapper activeLink={activeLink} updateActiveLink={handleUpdateActiveLink} />
            </div>
        </header>
        <div ref={dropdownCartRef} className={activeLink === 'cart' ? 'minicart active' : 'minicart'}>
            {cartDatas.length > 0 &&
                <div className="minicart-container">
                    <div className="mini-content">
                        <div className="minicart-head">
                            <a className="js-close-minicart" onClick={(e) => handleUpdateActiveLink(e,'')}>
                                <svg viewBox="0 0 298.667 298.667">
                                    <g>
                                        <g>
                                            <polygon points="298.667,30.187 268.48,0 149.333,119.147 30.187,0 0,30.187 119.147,149.333 0,268.48 30.187,298.667 149.333,179.52 268.48,298.667 298.667,268.48 179.52,149.333"></polygon>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                            <h3 className="title">Keranjang Belanja</h3>
                            <div className="minicart-counter">
                                <span className="js-minicart-counter">{cartCount}</span>
                            </div>
                        </div>
                        <div className="minicart-bottom">
                            <div className="shopping-list-wrapper">
                                <div className="product-cart">
                                    <ol>
                                        {cartDatas.map(cartData=>{
                                            return (
                                                <li key={cartData.id}>
                                                    <div className="thumb-img-product">
                                                        <img src={FOLDER_MEDIA_PATH+cartData.product_color.product_color_images[0].file.name} alt="productImage" />
                                                    </div>
                                                    <div className="product-detail">
                                                        <h3>{cartData.product.name}</h3>
                                                        <div className="product-info">
                                                            <div className="product-qty">Qty : <span>{cartData.quantity}</span></div>
                                                            <div className="product-price">
                                                                <span>Rp. {thousandSeparator(cartData.price)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-remove">
                                                        <a className="js-remove-product-cart" onClick={(e)=>removeItem(e,cartData)}>
                                                            <svg viewBox="0 0 1080 1080"><path d="M268,1080c-9.24-3.11-19-5.22-27.64-9.5-36.39-18-51.56-49-54.65-88.38-10.3-131.67-21.38-263.28-32.25-394.91-5.23-63.36-10.67-126.71-15.95-190.07-.32-3.83,0-7.71,0-12.36h806c-1.87,23.49-3.65,46.8-5.58,70.11Q929.9,550.44,921.89,646q-9.17,110.27-18.31,220.53c-3.55,42.71-6.32,85.5-10.93,128.09-4.81,44.4-32.93,74-76.78,83.68a19.45,19.45,0,0,0-3.73,1.69ZM381.87,700.73q0-109.58,0-219.17c0-17-6.82-27.47-20.37-32.08-22.9-7.8-42.84,7.13-42.85,32.35q-.08,201.78,0,403.57c0,12.64-.26,25.3.12,37.93.42,14.13,8.26,24.11,21.29,28.13,22.47,6.93,41.77-7.43,41.82-31.55Q382,810.31,381.87,700.73Zm189.83-.15q0-109.58,0-219.17c0-16.91-6.89-27.4-20.51-32-22.94-7.71-42.7,7.23-42.71,32.54q-.08,212.32,0,424.64c0,6-.24,12,.15,17.91.87,13.45,8.67,23.09,21.4,27,22.5,6.85,41.63-7.53,41.67-31.73Q571.83,810.16,571.69,700.58Zm126.55-.29q0,16.34,0,32.68,0,93.3,0,186.59c0,16.75,7.08,27.29,20.82,31.76,22.67,7.38,42.37-7.28,42.39-31.93q.14-147.59,0-295.17c0-47.44-.07-94.88,0-142.32,0-13.85-5.06-24.7-17.63-31.1-21.73-11.08-45.57,4.61-45.63,30.22Q698.08,590.66,698.25,700.29Z"/><path d="M542.2,316.48q-214.55,0-429.1-.1A135.81,135.81,0,0,1,87,314c-12.56-2.49-20.7-10.65-20.89-23.17-.42-28.41-2-57.18,1.6-85.18,6-46.19,45.69-78.61,92.57-78.9q88.56-.55,177.12.11c8.4.06,12.43-2.09,14.84-10.38,5-17.17,10.9-34.08,16.8-51C383.17,25,417.45.3,460.14.15q80.13-.29,160.25,0c41.64.17,76.12,24.59,90.2,63.78q9.6,26.74,17.88,53.95c2.11,7,5.37,9,12.61,9,57.63-.34,115.27-.22,172.9-.18,60.3,0,100.52,40.16,100.63,100.31q0,26.88,0,53.77c-.09,23.16-12.66,35.71-35.94,35.72Q760.44,316.51,542.2,316.48ZM415.39,126H664.73c-5-14.8-9.4-28.84-14.63-42.55-5-13.2-15.47-19.89-29.41-19.94q-80.64-.3-161.28,0c-14,.05-24.38,6.79-29.43,19.94C424.72,97.11,420.41,111.17,415.39,126Z"/></svg>
                                                        </a>
                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ol>
                                </div>
                            </div>
                            <div className="sub-total clearfix">
                                <div className="total-title">Total</div>
                                <div className="total-price">Rp. {thousandSeparator(dataOrder)}</div>
                            </div>
                            <div className="action-checkout">
                                <a onClick={(e)=>handleClick(e,'','cart')} className="btn-viewcart">
                                    <span>Lihat Keranjang</span>
                                </a>
                                <a onClick={(e)=>checkout(e,cartDatas)} className="btn-checkout">
                                    <span>Bayar</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {cartDatas.length === 0 &&
                <div className="minicart-container">
                    <div className="mini-content">
                        <div className="minicart-head">
                            <a className="js-close-minicart" onClick={(e) => handleUpdateActiveLink(e,'')}>
                                <svg viewBox="0 0 298.667 298.667">
                                    <g>
                                        <g>
                                            <polygon points="298.667,30.187 268.48,0 149.333,119.147 30.187,0 0,30.187 119.147,149.333 0,268.48 30.187,298.667 149.333,179.52 268.48,298.667 298.667,268.48 179.52,149.333"></polygon>
                                        </g>
                                    </g>
                                </svg>
                            </a>
                            <h3 className="title">Keranjang Belanja</h3>
                            <div className="minicart-counter">
                                <span className="js-minicart-counter">{cartCount}</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div className={activeLink === 'usermenu' ? 'usermenu active' : 'usermenu'}>
            <div className="usermenu-container">
                <div className="mini-content">
                    <div className="usermenu-head">
                        <a className="js-close-usermenu" onClick={(e) => handleUpdateActiveLink(e,'')}>
                            <svg viewBox="0 0 298.667 298.667">
                                <g>
                                    <g>
                                        <polygon points="298.667,30.187 268.48,0 149.333,119.147 30.187,0 0,30.187 119.147,149.333 0,268.48 30.187,298.667 149.333,179.52 268.48,298.667 298.667,268.48 179.52,149.333   "></polygon>
                                    </g>
                                </g>
                            </svg>
                        </a>
                        <h3 className="title">User Menu</h3>
                    </div>
                    <div className="usermenu-bottom">
                        <div className="menulogin-wrapper">
                            {stateLogin ?
                                <div className="form-login">
                                    <form>
                                        <div className="form-group mb10px">
                                            <div className="mb0">
                                                <input type="text" ref={emailRef} onClick={(e) => handleFocus(e,'email')} onBlur={(e) => handleBlur(e,'email')} onChange={(e) => handleChange(e,'email')} id="emailLogin1" name="emailLogin1" className="form-control login-input" autoComplete="on"/>
                                                <label className={`${emailLabelActive} ${emailFilled}`} htmlFor="emailLogin1">Email</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <input type="password" ref={passwordRef} onClick={(e) => handleFocus(e,'password')} onBlur={(e) => handleBlur(e,'password')} onChange={(e) => handleChange(e,'password')} id="passwordLogin1" name="passwordLogin1" className="form-control login-input" autoComplete="off" required={true}/>
                                                <label className={`${passwordLabelActive} ${passwordFilled}`} htmlFor="passwordLogin1">Password</label>
                                            </div>
                                        </div>
                                        <div className="form-check">
                                            <Link to="/lupa-password">Lupa Password?</Link>
                                        </div>
                                        <button className="btnNine btnNine-create js-login-user" onClick={handleLogin}>
                                            <svg viewBox="0 0 1024 1024">
                                                <circle cx="512" cy="512" r="512" style={{fill:'#fff'}} />
                                                <path d="m458.15 617.7 18.8-107.3a56.94 56.94 0 0 1 35.2-101.9V289.4h-145.2a56.33 56.33 0 0 0-56.3 56.3v275.8a33.94 33.94 0 0 0 3.4 15c12.2 24.6 60.2 103.7 197.9 164.5V622.1a313.29 313.29 0 0 1-53.8-4.4zM656.85 289h-144.9v119.1a56.86 56.86 0 0 1 35.7 101.4l18.8 107.8A320.58 320.58 0 0 1 512 622v178.6c137.5-60.5 185.7-139.9 197.9-164.5a33.94 33.94 0 0 0 3.4-15V345.5a56 56 0 0 0-16.4-40 56.76 56.76 0 0 0-40.05-16.5z" style={{fill:'#363c4f'}} />
                                            </svg>
                                            Masuk
                                        </button>
                                        <div className="or-create">
                                            <span>or</span>
                                            <div>
                                                <a className="register-btn" onClick={(e)=>changeStateLogin(e,false)}>Daftar Sekarang</a>
                                            </div>
                                        </div>
                                    </form>
                                </div> :
                                <div className="form-register">
                                    <form>
                                        <div className="form-group mb10px">
                                            <div className="mb0">
                                                <input type="text" ref={usernameRegisterRef} onClick={(e) => handleRegisterFocus(e,'username')} onBlur={(e) => handleRegisterBlur(e,'username')} onChange={(e) => handleRegisterChange(e,'username')} name="username" id="username" className="form-control login-input" required/>
                                                <label className={`${usernameRegisterLabelActive} ${usernameRegisterFilled}`} htmlFor="username">Username</label>
                                            </div>
                                        </div>
                                        <div className="form-group mb10px">
                                            <div className="mb0">
                                                <input type="text" ref={emailRegisterRef} onClick={(e) => handleRegisterFocus(e,'email')} onBlur={(e) => handleRegisterBlur(e,'email')} onChange={(e) => handleRegisterChange(e,'email')} id="email" name="Email" className="form-control login-input" autoComplete="on" required/>
                                                <label className={`${emailRegisterLabelActive} ${emailRegisterFilled}`} htmlFor="email">Email Address</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div>
                                                <input type="password" ref={passwordRegisterRef} onClick={(e) => handleRegisterFocus(e,'password')} onBlur={(e) => handleRegisterBlur(e,'password')} onChange={(e) => handleRegisterChange(e,'password')} id="password" name="password" className="form-control login-input" autoComplete="off" required/>
                                                <label className={`${passwordRegisterLabelActive} ${passwordRegisterFilled}`} htmlFor="password">Password</label>
                                            </div>
                                        </div>
                                        <button className="btnNine btnNine-create" onClick={handleRegister}>
                                            Daftar
                                        </button>
                                        <div className="or-create">
                                            <span>atau</span>
                                            <div>
                                                <a className="login-btn" onClick={(e)=>changeStateLogin(e,true)}>Kembali ke login</a>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            }
                            {currentUser &&
                                <div className="success-login-wrapper" style={{display:'none'}}>
                                    <div className="user-photo">
                                        <img src={currentUser.imageName ? FOLDER_MEDIA_PATH+currentUser.imageName : FOLDER_MEDIA_PATH+'ICON-USER.png'} alt="profileImage" />
                                    </div>
                                    <div className="user-name">{currentUser.name}</div>
                                    <Link className="btnNine btnNine-default btn-profile" to="/profil">Lihat Profil</Link>
                                    <div className="purchase-title">Pembelian Terakhir</div>
                                    <div className="product-cart">
                                        <ol>
                                            <li>
                                                <div className="thumb-img-product">
                                                    <img src={FOLDER_MEDIA_PATH+"Q2-PNG.png"}  alt="productCartImage" />
                                                </div>
                                                <div className="product-detail">
                                                    <h3>SHL-Q2</h3>
                                                    <div className="product-info">
                                                        <div className="product-date"><span>20 Agustus 2023 03:45 PM</span></div>
                                                        <div className="product-price">
                                                            <span>Rp. 56.000</span>
                                                        </div>
                                                        <a className="btnNine btnNine-default btn-buy" href="product.html">Beli Lagi</a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="thumb-img-product">
                                                    <img src={FOLDER_MEDIA_PATH+"Q2-PNG.png"} alt="thumbImage"  />
                                                </div>
                                                <div className="product-detail">
                                                    <h3>SHL-Q2</h3>
                                                    <div className="product-info">
                                                        <div className="product-date"><span>18 Agustus 2023 05:23 PM</span></div>
                                                        <div className="product-price">
                                                            <span>Rp. 56.000</span>
                                                        </div>
                                                        <a className="btnNine btnNine-default btn-buy" href="product.html">Beli Lagi</a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                    <button type="" className="btnNine btnNine-create btn-logout js-logout-user">
                                        Keluar
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MobileMenuList activeLink={activeLink} updateActiveLink={handleUpdateActiveLink} bgOverlay={bgOverlay} changeBgOverlay={changeBgOverlay} menu={menu} onUpdateMenu={onUpdateMenu} />
        <div className={bgOverlay ? 'bg-overlay active' : 'bg-overlay'} ></div>
    </>
  )
}

export default HeaderMainContainer