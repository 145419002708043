import { createContext, useEffect, useState } from "react";
import { makeRequest } from "../axios.js";

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
    const [currentUser,setCurrentUser] = useState(JSON.parse(localStorage.getItem("user")) || null);

    const login = async (inputs) => {
        try {
            const result = await makeRequest.post("auth/login",inputs,{
                withCredentials:true
            });
            setCurrentUser(result.data.data);
            return {status:'SUCCESS',message:'Login berhasil!'}
        } catch (error) {
            setCurrentUser(null);
            var message = '';
            if(error.response.status === 400 || error.response.status === 403 || error.response.status === 404){
                message = error.response.data.message;
            } else {
                message = 'Servis sedang mengalami gangguan, dimohon untuk mencoba kembali lagi nanti!';
            }
            return {status:'ERROR',message:message}
        }
    }

    const logout = async () => {
        try {
            await makeRequest.post("auth/logout",{
                withCredentials:true
            });
            setCurrentUser(null);
        } catch (error) {
            alert('Servis sedang mengalami gangguan, dimohon untuk mencoba kembali lagi nanti!');
        }
    }

    const register = async(inputs) => {
        try {
            const result = await makeRequest.post("auth/register",inputs,{
                withCredentials:true
            });
            return {status:'SUCCESS',message:result.data.message}
        } catch (error) {
            var message = '';
            if(error.response.status === 409){
                message = error.response.data.message;
            } else {
                message = 'Servis sedang mengalami gangguan, dimohon untuk mencoba kembali lagi nanti!';
            }
            return {status:'ERROR',message:message}
        }
    }

    useEffect(()=>{
        localStorage.setItem("user",currentUser ? JSON.stringify(currentUser) : null);
    },[currentUser]);

    return (
        <AuthContext.Provider value={{currentUser,login,logout,register}}>
            {children}
        </AuthContext.Provider>
    )
}