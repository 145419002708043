import { useNavigate } from "react-router-dom"
import gsap from 'gsap';
import { useCallback } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { FOLDER_MEDIA_PATH } from "../utils";
import { makeRequest } from "../axios";

const Category = ({datas}) => {
    const navigate = useNavigate();
    gsap.registerPlugin(ScrollTrigger);

    const categoryRef = useCallback((node) => {
        if (!node) return;
        const divs = node.querySelectorAll('.template-item')
        gsap.to(divs, {
            autoAlpha:1,
            y:0,
            stagger: 0.3,
            overwrite:true,
            duration: .3
        });
    },[]);
    const getSubCategoriesByCategory = async(categoryName) => {
        try {
            const response = await makeRequest.get("sub-categories/find-by-category-name?categoryName="+categoryName);
            const result = response.data.data.map(category=>category.name);
            return result;
        } catch (error) {
            return [];
        }
    }
    const handleClick = async(value) => {
        const subCategories = await getSubCategoriesByCategory(value);
        navigate('produk?kategori='+value+'&halaman=1&urutkan=produk_terlaris&subKategori='+subCategories);
    }
  return (
    <>
        {datas.length>0 &&
            <div className="section product-display-home">
                <div className="container inner">
                    <div className="template-container" ref={categoryRef}>
                        {datas.map(data=>{
                            return (
                                <div className="template-item" onClick={()=>handleClick(data.name)} key={data.id}>
                                    <div className="template-card">
                                        <div className="template-card-header">
                                            <img src={FOLDER_MEDIA_PATH+data.pathImage} alt="categoryImage" />
                                        </div>
                                        <div className="template-card-desc">
                                            <div className="template-product-name" data-text={data.name}>{data.name}</div>
                                            <p className="template-product-desc">{data.description}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        }
    </>
  )
}

export default Category