import { Link } from 'react-router-dom';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import {FOLDER_MEDIA_PATH} from '../utils.js';
import { makeRequest } from '../axios.js';

const Catalogue = () => {
    // gsap.registerPlugin(ScrollTrigger);
    const [datas,setDatas] = useState([]);

    // const catalogueRef = useCallback((node) => {
    //     if (!node) return;
    //     const divs = node.querySelectorAll('.template-item')
    //     gsap.to(divs, {
    //         autoAlpha:1,
    //         y:0,
    //         stagger: 0.3,
    //         overwrite:true,
    //         duration: .6
    //     });
    // },[]);
    const initData = async() => {
        try {
            const result = await makeRequest.get('downloads/find-by-type?type=katalog');
            setDatas(result.data.data);
        } catch (error) {
            setDatas([]);
        }
    }
    useEffect(()=>{
        initData();
    },[])
  return (
    <>
        <div className="page-title-container">
            <img src={FOLDER_MEDIA_PATH+"Banner News.jpg"} alt="newsBannerImage" />
            <div className="content-container">
                <h1>Katalog</h1>
                <ul className="breadcrumbs">
                    <li><Link to="/">Beranda</Link></li>
                    <li>Katalog</li>
                </ul>
            </div>
        </div>
        <div className="section section-brochure brochure-wrapper" style={{minHeight:'calc(100vh - 400px)'}}>
            <div className="container">
                <div className="template-container" style={{justifyContent:'flex-start'}}>
                    {datas?.map(data=>(
                        <div className="template-item" onClick={()=>window.open(FOLDER_MEDIA_PATH+data.file.name,'_blank')}>
                            <div className="template-card">
                                <div className="template-card-header">
                                    <img src={FOLDER_MEDIA_PATH+data.image.name} alt="catalogue"/>
                                </div>
                                <div className="template-card-desc">
                                    <div className="template-product-name" data-text="Katalog">{data.type}</div>
                                    <p className="template-product-desc">{data.name}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </>
  )
}

export default Catalogue