import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from '../context/authContext.js';
import { makeRequest } from '../axios.js';
import { FOLDER_MEDIA_PATH } from "../utils.js";

const MobileWrapper = ({activeLink,updateActiveLink}) => {
    const {currentUser,logout} = useContext(AuthContext);
    const [err,setErr] = useState(null);

    // const [activeLink,setActiveLink] = useState(activeLinkData);

    const [cartCount,setCartCount] = useState(0);
    const [cartDatas,setCartDatas] = useState([]);
    const [showNotification,setShowNotification] = useState(false);
    const [notificationDatas,setNotificationDatas] = useState([]);
    const [notificationCount,setNotificationCount] = useState(0);

    const showNotificationRef = useRef();
    const initNotificationDatas = async() => {
        try {
            if(currentUser) {
                const result = await makeRequest.get("notifications/find-by-userId-and-unread?userId="+currentUser.id);
                setNotificationDatas(result.data.data);
                setNotificationCount(result.data.data.length);
            } else {
                setNotificationDatas([]);
                setNotificationCount(0);
            }
        } catch(err){
            setNotificationDatas([]);
            setNotificationCount(0);
        }
    }
    const initCartDatas = async() => {
        if(currentUser){
            try {
                const result = await makeRequest.get('carts/find-all');
                let datas = result.data.data;
                setCartDatas(datas);
                setCartCount(datas.length);
            } catch (error) {
                setCartDatas([]);
                setCartCount(0);
                if(error.response.status===403 || error.response.status===401){
                    logout();
                    alert(error.response.data.message);
                }
            }
        } else {
            setCartDatas([]);
            setCartCount(0);
        }
    }
    const handleUpdateActiveLink = (e,value) => {
        if(value==='notification'){
            setShowNotification(!showNotification);
        }
        updateActiveLink(e,value);
    }
    const closePopupNotification = (e) => {
        e.preventDefault();
        if(!showNotificationRef.current.contains(e.target)){
            setShowNotification(false);
        }
    }
    useEffect(()=>{
        initNotificationDatas();
        initCartDatas();
        if(activeLink === 'notification'){
            document.addEventListener("mousedown", closePopupNotification);
        }
        return () => {
            if(activeLink === 'notification'){
                document.removeEventListener("mousedown", closePopupNotification);
            }
        }
    },[])
  return (
    <div className="mobile-menu-wrapper">
        <div className="notif-mobile">
            <svg className="js-notif-mobile" viewBox="0 0 1080 1080" onClick={(e)=>handleUpdateActiveLink(e,'notification')}><path className="cls-1" d="M1004.41,816.56H75.59V778.81c0-68.33,6.64-128.2,81.67-163.35v-122C157.26,338.62,259,200,408.09,147.19v-19c0-38.82,17.8-75.12,48.83-99.59C489.37,3,531.7-6,573,3.94c57.3,13.74,98.88,67.54,98.88,127.93v15.33C821,200,922.74,338.62,922.74,493.51V615.3c81.67,36.69,81.67,92.63,81.67,161.85Zm-852-75.49H928.44c-.66-14.37-2.24-25.55-6-33.37S909.6,689.19,873,676.88l-25.72-8.65V493.51c0-130.39-91.69-246.43-223-282.19l-27.83-7.58V131.87c0-25.89-17.24-48.82-41-54.52-18.72-4.49-37.59-.65-51.77,10.54C490.9,98,483.58,112.65,483.58,128.2v75.55l-27.83,7.58c-131.29,35.76-223,151.8-223,282.19V668.36l-25.89,8.57C168.86,689.51,156.06,701.09,152.38,741.07Z"/><path className="cls-1" d="M540,1080A122.3,122.3,0,1,1,662.3,957.7,122.44,122.44,0,0,1,540,1080Zm0-169.1a46.8,46.8,0,1,0,46.8,46.8A46.85,46.85,0,0,0,540,910.9Z"/></svg>
            <div ref={showNotificationRef} className="dropdown-notification-mobile" style={{display: showNotification ? 'block':'none'}}>
                <ul className="dropdown-notif-list">
                {/* {notificationDatas.length > 0 && notificationDatas.map(data=>{
                    return (

                    )
                })} */}
                    <li className="dropdown-notif-item-mobile">
                        <a>
                            <div className="row-notif-product">
                                <div className="notif-foto">
                                    <img src={FOLDER_MEDIA_PATH+"product01.jpg"} alt="productImage" />
                                </div>
                                <div className="notif-desc">
                                    <div className="notif-product-name">Pesanan sedang dikirim</div>
                                    <div className="notif-no-pesanan">NN223108671251 (Nomor pesanan)</div>
                                    <div className="notif-date">06-10-2023 12.14</div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li className="dropdown-notif-item-mobile">
                        <a>
                            <div className="row-notif-product">
                                <div className="notif-foto">
                                    <img src={FOLDER_MEDIA_PATH+"product01.jpg"} alt="productImage" />
                                </div>
                                <div className="notif-desc">
                                    <div className="notif-product-name">Pesanan sedang dikirim</div>
                                    <div className="notif-no-pesanan">NN223108671251 (Nomor pesanan)</div>
                                    <div className="notif-date">06-10-2023 12.14</div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li className="dropdown-notif-item-mobile">
                        <a>
                            <div className="row-notif-border read">Klaim produk Anda, disetujui!</div>
                        </a>
                    </li>
                    <li className="dropdown-notif-item-mobile">
                        <a>
                            <div className="row-notif-border unread">Klaim produk Anda, ditolak!</div>
                        </a>
                    </li>
                    <li className="dropdown-notif-item-mobile">
                        <a>
                            <div className="row-notif-border read">GJAW 2023, OPEN TODAY!</div>
                        </a>
                    </li>
                    <li className="dropdown-notif-item-mobile">
                        <a>
                            <div className="row-notif-border unread">IIMS 2024, OPEN TODAY! HALL B2 D11</div>
                        </a>
                    </li>
                    <li className="dropdown-notif-item-mobile">
                        <a>
                            <div className="row-notif-border read">PRODUK LAMPU ZAMAN NOW, COBAIN!</div>
                        </a>
                    </li>
                </ul>
                {notificationDatas.length > 0 ? (<div className="text-center pv5px">
                    <a href="notification.html" className="green fz12 dropdown-notif-item-mobile">Lihat Selengkapnya</a>
                </div>) :
                (<div className="text-center pv5px">
                    <span>Tidak ada notifikasi</span>
                </div>)}
            </div>
        </div>
        <a className="mobile-cart js-show-cart" onClick={(e)=>handleUpdateActiveLink(e,'cart')}>
            <svg viewBox="0 0 260.293 260.293">
                <g>
                    <path d="M258.727,57.459c-1.42-1.837-3.612-2.913-5.934-2.913H62.004l-8.333-32.055c-0.859-3.306-3.843-5.613-7.259-5.613H7.5
                        c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5h33.112l8.333,32.055c0,0.001,0,0.001,0.001,0.002l29.381,112.969
                        c0.859,3.305,3.843,5.612,7.258,5.612h137.822c3.415,0,6.399-2.307,7.258-5.612l29.385-112.971
                        C260.636,61.687,260.147,59.295,258.727,57.459z M117.877,167.517H91.385l-5.892-22.652h32.384V167.517z M117.877,129.864H81.592
                        l-5.895-22.667h42.18V129.864z M117.877,92.197H71.795l-5.891-22.651h51.973V92.197z M176.119,167.517h-43.242v-22.652h43.242
                        V167.517z M176.119,129.864h-43.242v-22.667h43.242V129.864z M176.119,92.197h-43.242V69.546h43.242V92.197z M217.609,167.517
                        h-26.49v-22.652h32.382L217.609,167.517z M227.403,129.864h-36.284v-22.667h42.18L227.403,129.864z M237.201,92.197h-46.081V69.546
                        h51.974L237.201,92.197z"/>
                    <path d="M105.482,188.62c-15.106,0-27.396,12.29-27.396,27.395c0,15.108,12.29,27.4,27.396,27.4
                        c15.105,0,27.395-12.292,27.395-27.4C132.877,200.91,120.588,188.62,105.482,188.62z M105.482,228.415
                        c-6.835,0-12.396-5.563-12.396-12.4c0-6.835,5.561-12.395,12.396-12.395c6.834,0,12.395,5.561,12.395,12.395
                        C117.877,222.853,112.317,228.415,105.482,228.415z"/>
                    <path d="M203.512,188.62c-15.104,0-27.392,12.29-27.392,27.395c0,15.108,12.288,27.4,27.392,27.4
                        c15.107,0,27.396-12.292,27.396-27.4C230.908,200.91,218.618,188.62,203.512,188.62z M203.512,228.415
                        c-6.833,0-12.392-5.563-12.392-12.4c0-6.835,5.559-12.395,12.392-12.395c6.836,0,12.396,5.561,12.396,12.395
                        C215.908,222.853,210.347,228.415,203.512,228.415z"/>
                </g>
            </svg>
            {cartCount>0 ?
                <span className="js-number-cart number-cart-mobile active">{cartCount}</span>
                : <span className="js-number-cart number-cart-mobile"></span>
            }
        </a>
        <a className="hamburger-menu js-hamburger" onClick={(e)=>handleUpdateActiveLink(e,'mobile-menu')}>
            <svg viewBox="0 0 23 23" fill="none">
                <rect x="0.5" y="0.5" width="9" height="9" rx="1.9" fill="none" stroke="#FFFFFF"></rect>
                <rect y="13" width="10" height="10" rx="2.4" fill="#FFFFFF"></rect>
                <rect x="13" width="10" height="10" rx="2.4" fill="#FFFFFF"></rect>
                <rect x="13" y="13" width="10" height="10" rx="2.4" fill="#FFFFFF"></rect>
            </svg>
        </a>
    </div>
  )
}

export default MobileWrapper