import { Link } from 'react-router-dom';
import { useLayoutEffect, useState } from 'react';
import { makeRequest } from "../axios.js";
import { FOLDER_MEDIA_PATH, convertDate } from '../utils';
import queryString from 'query-string';
import { useContext } from 'react';
import { AuthContext } from '../context/authContext';
import HTMLReactParser from 'html-react-parser';

const News = () => {
    const formatDate = "dd-MM-yyyy";
    const {currentUser} = useContext(AuthContext);
    const [queryParam,setQueryParam] = useState({
        page:1,
        sortBy:'id',
        userId:currentUser ? currentUser.id : ''
    });
    const [err,setErr] = useState({});
    const [datas,setDatas] = useState(null);
    const [archives,setArchives] = useState([]);
    const [categories,setCategories] = useState([]);
    const dataNews = async() => {
        try {
            const result = await makeRequest.get('news/pagination?'+queryString.stringify(queryParam));
            setDatas(result.data);
        } catch (error) {
            setDatas(null);
            setErr(error);
        }
    }
    const initArchives = async() => {
        try {
            const result = await makeRequest.get('news-archives/find-all');
            setArchives(result.data.data);
        } catch (error) {
            setArchives([]);
        }
    }
    const initCategories = async() => {
        try {
            const result = await makeRequest.get('news-categories/find-all');
            setCategories(result.data.data);
        } catch (error) {
            setCategories([]);
        }
    }
    useLayoutEffect(()=>{
        dataNews();
        initArchives();
        initCategories();
    },[queryParam.page]);
    const onClickPaging = (e,pageValue) => {
        e.preventDefault();
        setQueryParam({...queryParam, page:pageValue});
        // dataNews();
    }

    const onClickPagingPrevOrNext = (e,type) => {
        e.preventDefault();
        if(type==='prev'){
            setQueryParam({...queryParam, page:queryParam.page-1});
        } else if(type==='next'){
            setQueryParam({...queryParam, page:queryParam.page+1});
        }
        // dataNews();
    }
  return (
    <>
        <div className="page-title-container">
            <img src={FOLDER_MEDIA_PATH+"Banner News.jpg"} alt="newsBannerImage" />
            <div className="content-container">
                <h1>Berita</h1>
                <ul className="breadcrumbs">
                    <li><Link to="/">Beranda</Link></li>
                    <li>Berita</li>
                </ul>
            </div>
        </div>
        <div className="page-container">
            <div className="content-container">
                <div className="section-news row">
                    <div className="left-section-news">
                        {datas !== null && datas.result.map(data=>{
                            return (
                                <div key={data.id}>
                                    <div className="news-item">
                                        <h4 className="news-title">
                                            <Link to={'/detail-berita/'+data.id}>{data.title}</Link>
                                        </h4>
                                        <div className="news-header">
                                            <Link to={'/detail-berita/'+data.id}>
                                                <img src={FOLDER_MEDIA_PATH+data.pathImage} alt="newsImage" />
                                            </Link>
                                            <div className="meta">
                                                <span className="post-date"><i className="fa fa-calendar" aria-hidden="true"></i>{convertDate(formatDate,data.createdAt)}</span>
                                                <span className="post-category">Category: <a role="button">Coaching</a><a role="button">Lorem</a></span>
                                            </div>
                                        </div>
                                        <div className="news-content">
                                            {HTMLReactParser(data.description)}
                                        </div>
                                        <div className="news-footer">
                                            <div className="meta">
                                                <span>
                                                    <a role="button">{data.news_comments.length > 0 ? data.news_comments.length : 'Tidak ada'} Komentar</a>
                                                </span>
                                                {/* <span className="post-tags">Tags: <a role="button">Lorem</a><a role="button">Dorem</a></span> */}
                                            </div>
                                            <Link to={'/detail-berita/'+data.id} className="btnNine btnNine-default">Lihat Selengkapnya</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })} 
                        {datas !== null &&
                        <div className="pagination-cover">
                            <ul className="pagination" style={{justifyContent:"flex-start"}}>
                            {datas.totalPage > 5 && queryParam.page > 1 &&
                                <li className="pagination-item item-prev" onClick={(e)=>onClickPagingPrevOrNext(e,'prev')}>
                                    <a>
                                        <i className="fa fa-angle-left" aria-hidden="true"></i>
                                    </a>
                                </li>
                            }
                            {Array.from({length: 5}, (_,i)=>{
                                if(queryParam.page >= datas.totalPage / 2){
                                    const pivot = Math.ceil(datas.totalPage / 2)-1;
                                    return (
                                        <li onClick={(e)=>onClickPaging(e,i+pivot)} key={i+pivot} className={`pagination-item ${queryParam.page === i+pivot ? "active" : ""}`}>
                                            <a>{i+pivot}</a>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li onClick={(e)=>onClickPaging(e,i+queryParam.page)} key={i+queryParam.page} className={`pagination-item ${queryParam.page === (i+queryParam.page) ? "active" : ""}`}>
                                            <a>{i+queryParam.page}</a>
                                        </li>
                                    )
                                }
                            })}
                            {datas.totalPage > 5 && queryParam.page < datas.totalPage &&
                                <li className="pagination-item item-next" onClick={(e)=>onClickPagingPrevOrNext(e,'next')}>
                                    <a>
                                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                                    </a>
                                </li>
                            }
                            </ul>
                        </div>
                        }
                    </div>
                    <div className="right-section-news">
                        <ul className="widgets">
                            <li className="widget-list widget-archive">
                                <h5 className="title">Archive</h5>
                                <ul>
                                    {archives?.map(archive=>(
                                        <li key={archive.id}><Link to={`/berita/arsip/${archive.path}`} role="button">{archive.name}</Link></li>
                                    ))}
                                </ul>
                            </li>
                            <li className="widget-list widget-categories">
                                <h5 className="title">Kategori</h5>
                                <ul>
                                    {categories?.map(category=>(
                                        <li key={category.id}><Link to={`/berita/kategori/${category.name}`} role="button">{category.name}</Link></li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default News