import { useContext, useEffect, useState } from "react";
import UserWidget from "../components/UserWidget";
import UserInfo from "../components/UserInfo";
import UserContent from "../components/UserContent";
import { useLocation,useNavigate } from "react-router-dom";
import {AuthContext} from '../context/authContext.js';

const UserProfile = ({setLoginClicked}) => {
    let location = useLocation();
    const navigate = useNavigate();
    const [currentState,setCurrentState] = useState(location.state ? location.state.widget : "produk");
    const {currentUser} = useContext(AuthContext);
    const handleChange = (value) => {
        setCurrentState(value);
    }
    useEffect(()=>{
      if(!currentUser){
          navigate("/");
      }
    },[]);
  return (
    <div className="section-profile profile-wrapper">
      {currentUser &&
        <div className="container">
            <div className="profile-title-page">Halaman Saya</div>
            <UserInfo/>
            <UserWidget data={currentState} onChange={handleChange} />
            <UserContent data={currentState} onChange={handleChange} setLoginClicked={setLoginClicked} />
        </div>
      }
    </div>
  )
}

export default UserProfile