import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/authContext'
import { makeRequest } from "../axios.js";
import {FOLDER_MEDIA_PATH,thousandSeparator} from '../utils.js';

const UserCart = () => {
    const {currentUser} = useContext(AuthContext);
    const [datas,setDatas] = useState([]);
    const [checkAll,setCheckAll] = useState(false);
    const [total,setTotal] = useState(0);
    const [finalTotal,setFinalTotal] = useState(0);
    const [orderId,setOrderId] = useState('');
    useEffect(()=>{
        getUserCarts();
    },[]);
    const getUserCarts = async() => {
        try {
            const result = await makeRequest.get('carts/find-all');
            let updatedList = result.data.data.map(data=>{
                return {
                    id:data.id,
                    product:data.product,
                    isChecked:false,
                    notesActive:false,
                    notes:'',
                    counter:data.quantity,
                    price:data.price,
                    imageName:data.product_color.product_color_images[0].file.name
                }
            });
            setDatas(updatedList);
            setOrderId(updatedList[0].orderId);
        } catch (error) {
            console.log(error);
            setDatas([]);
            setOrderId('');
        }
    }
    const handleChangeCheckAll = () => {
        setCheckAll(!checkAll)
        let updatedList = datas.map(data=>{
            return {
                ...data,isChecked:!checkAll
            }
        });
        setDatas(updatedList);
    }
    const handleChangeCheckbox = (e,id) => {
        let list = [...datas];
        let newList = list.map(data=>{
            if(data.id===id){
                data.isChecked=e.target.checked;
                let price = parseInt(data.price) * parseInt(data.counter);
                setTotal(e.target.checked ? total+price : total-price);
            }
            return data;
        })
        setDatas(newList);
        checkAllCheckbox();
    }
    const checkAllCheckbox = () => {
        let list = [...datas];
        let countCheckbox = 0;
        list.map(data=>data.isChecked ? countCheckbox+=1 : countCheckbox+=0);
        let value = false;
        if(countCheckbox == list.length){
            value = true;
        }
        setCheckAll(value);
    }
    const handleCounter = (counterType,id) => {
        let list = [...datas];
        let newList = list.map(data=>{
            if(data.id===id && counterType === 'minus'){
                data.counter = data.counter===1 ? 1 : data.counter-1;
            } else if(data.id===id && counterType === 'plus') {
                data.counter = data.counter===10 ? 10 : data.counter+1;
            }
            return data;
        });
        setDatas(newList);
    }
    const openNotes = (e,id) => {
        let list = [...datas];
        let newList = list.map(data=>{
            if(data.id===id){
                data.notesActive=!data.notesActive;
            }
            return data;
        })
        setDatas(newList);
    }
    const notesChange = (e,id) => {
        let updatedList = datas.map(data=>{
            if(data.id===id){
                return {
                    ...data,notes:e.target.value
                }
            }
        });
        setDatas(updatedList);
    }
    const deleteItem = (e) => {
        e.preventDefault();
        alert('this item will be deleted later')
    }
    const pay = async(e,datas) => {
        alert('Fitur ini masih dalam tahap proses pengembangan. Mohon dicoba lain waktu, terima kasih!');
    }
  return (
    <div className="content-profile">
        {datas.length>0 &&
            <div className="content-profile-list active">
                <div className="text-center mb2">
                    <div className="bold-caption">Keranjang Belanja</div>
                </div>
                <div className="hello-user">Halo {currentUser.name}!</div>
                <div className="hello-desc">Berikut produk yang sudah masuk di keranjang Anda.</div>
                <div className="custom-checkbox-cart">
                    <input type="checkbox" className="js-check-cart" onChange={handleChangeCheckAll} checked={checkAll}/>
                    <span>Pilih Semua</span>
                </div>
                <div className="inline-space-start">
                    <div className="column-product-cart mr1">
                        {datas.map(data=>{
                            return (
                                <div className="product-item-cart" key={data.id}>
                                    <div className="product-cart-wrapper">
                                        <div className="custom-checkbox-cart">
                                            <input type="checkbox" checked={data.isChecked} className="cart-item-checkbox" onChange={(e)=>handleChangeCheckbox(e,data.id)} />
                                            <span>Pilih Produk</span>
                                        </div>
                                        <div className="trash-cart" onClick={deleteItem}>
                                            <svg viewBox="0 0 1080 1080"><path d="M268,1080c-9.24-3.11-19-5.22-27.64-9.5-36.39-18-51.56-49-54.65-88.38-10.3-131.67-21.38-263.28-32.25-394.91-5.23-63.36-10.67-126.71-15.95-190.07-.32-3.83,0-7.71,0-12.36h806c-1.87,23.49-3.65,46.8-5.58,70.11Q929.9,550.44,921.89,646q-9.17,110.27-18.31,220.53c-3.55,42.71-6.32,85.5-10.93,128.09-4.81,44.4-32.93,74-76.78,83.68a19.45,19.45,0,0,0-3.73,1.69ZM381.87,700.73q0-109.58,0-219.17c0-17-6.82-27.47-20.37-32.08-22.9-7.8-42.84,7.13-42.85,32.35q-.08,201.78,0,403.57c0,12.64-.26,25.3.12,37.93.42,14.13,8.26,24.11,21.29,28.13,22.47,6.93,41.77-7.43,41.82-31.55Q382,810.31,381.87,700.73Zm189.83-.15q0-109.58,0-219.17c0-16.91-6.89-27.4-20.51-32-22.94-7.71-42.7,7.23-42.71,32.54q-.08,212.32,0,424.64c0,6-.24,12,.15,17.91.87,13.45,8.67,23.09,21.4,27,22.5,6.85,41.63-7.53,41.67-31.73Q571.83,810.16,571.69,700.58Zm126.55-.29q0,16.34,0,32.68,0,93.3,0,186.59c0,16.75,7.08,27.29,20.82,31.76,22.67,7.38,42.37-7.28,42.39-31.93q.14-147.59,0-295.17c0-47.44-.07-94.88,0-142.32,0-13.85-5.06-24.7-17.63-31.1-21.73-11.08-45.57,4.61-45.63,30.22Q698.08,590.66,698.25,700.29Z"/><path d="M542.2,316.48q-214.55,0-429.1-.1A135.81,135.81,0,0,1,87,314c-12.56-2.49-20.7-10.65-20.89-23.17-.42-28.41-2-57.18,1.6-85.18,6-46.19,45.69-78.61,92.57-78.9q88.56-.55,177.12.11c8.4.06,12.43-2.09,14.84-10.38,5-17.17,10.9-34.08,16.8-51C383.17,25,417.45.3,460.14.15q80.13-.29,160.25,0c41.64.17,76.12,24.59,90.2,63.78q9.6,26.74,17.88,53.95c2.11,7,5.37,9,12.61,9,57.63-.34,115.27-.22,172.9-.18,60.3,0,100.52,40.16,100.63,100.31q0,26.88,0,53.77c-.09,23.16-12.66,35.71-35.94,35.72Q760.44,316.51,542.2,316.48ZM415.39,126H664.73c-5-14.8-9.4-28.84-14.63-42.55-5-13.2-15.47-19.89-29.41-19.94q-80.64-.3-161.28,0c-14,.05-24.38,6.79-29.43,19.94C424.72,97.11,420.41,111.17,415.39,126Z"/></svg>
                                        </div>
                                        <div className="product-cart-photo">
                                            <img src={FOLDER_MEDIA_PATH+data.imageName} alt="productImage" />
                                        </div>
                                        <div className="product-cart-desc">
                                            <div className="product-brand">{data.product.brand.name}</div>
                                            <div className="product-name">{data.product.name}</div>
                                            <div className="product-weight">{data?.weight}</div>
                                            <div className="product-price">{thousandSeparator(data.price)}</div>
                                            <div className="quantity-cart inline-space">
                                                <div className="counter js-counter" data-counter="minus" onClick={()=>handleCounter('minus',data.id)}>
                                                    <i className="fa fa-minus"></i>
                                                </div>
                                                <div className="counter-number">
                                                    <input type="text" name="counter-number" id="counter-number" className="form-control val-counter" value={data.counter} readOnly={true}/>
                                                </div>
                                                <div className="counter js-counter" data-counter="plus" onClick={()=>handleCounter('plus',data.id)}>
                                                    <i className="fa fa-plus"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fz12 green js-add-note" onClick={(e)=>openNotes(e,data.id)}>Tambah Catatan</div>
                                        <div className="form-group form-notes" style={data.notesActive ? {display:'block'} : {}}>
                                            <div className="mb0" style={{height:"80px"}}>
                                                <textarea className="form-control login-input" style={{height:"80px"}} value={data.notes} onChange={(e)=>notesChange(e,data.id)}></textarea>
                                                <label>Catatan</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="column-product-cart">
                        <div className="product-summary-cart">
                            <div className="product-cart-wrapper">
                                <div className="semibold fz16 black mb1">Ringkasan Belanja</div>
                                <div className="bbgrey w100">
                                    <div className="inline-space fz12 w100">
                                        <div>Total Harga</div>
                                        <div className="text-right">Rp. {thousandSeparator(total)}</div>
                                    </div>
                                </div>
                                <div className="bbgrey w100 mb1">
                                    <div className="inline-space fz14 semibold w100">
                                        <div>Total Pembayaran</div>
                                        <div className="text-right">Rp. {thousandSeparator(total)}</div>
                                    </div>
                                </div>
                                {total > 0 &&
                                    <div className="clearfix w100">
                                        <div className="pull-right">
                                            <a onClick={(e)=>pay(e,datas)}>
                                                <button className="btnNine btnNine-default">Bayar</button>
                                            </a>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        {datas.length === 0 &&
            <div className="content-profile-list active">
                <div className="text-center mb2">
                    <div className="bold-caption">Keranjang Belanja</div>
                </div>
                <div className="hello-user">Halo {currentUser.name}!</div>
                <div className="hello-desc">Tidak ditemukan produk pada keranjang anda.</div>
            </div>
        }
    </div>
  )
}

export default UserCart