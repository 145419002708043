import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useCallback, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { FOLDER_MEDIA_PATH } from "../utils";

const Promo = ({largePromo,promos}) => {
    gsap.registerPlugin(ScrollTrigger);
    const promoRef = useCallback((node) => {
        if (!node) return;
        const divs = node.querySelectorAll('.template-grid-item')
        gsap.to(divs, {
            autoAlpha:1,
            y:0,
            stagger: 0.3,
            overwrite:true,
            duration: .3
        });
    },[]);
  return (
    <>
        {promos.length>0 &&
            <div className="section product-best-home">
                <div className="container">
                    <div className="text-center title-wrapper">
                        <h4>Promo Terbaru</h4>
                        <div className="sub-heading">Dapatkan info promo produk terbaru di sini.</div>
                    </div>
                    <div className="template-container">
                        <div className="template-grid-wrapper" ref={promoRef}>
                            {largePromo.map(data=>{
                                return (
                                    <div key={data.id} className="template-grid-item large">
                                        <div className="template-grid-content">
                                            <img src={FOLDER_MEDIA_PATH+data.products[0].product_colors[0].product_color_images[0].file.name} alt="promoImage" />
                                            <div className="template-grid-desc">
                                                <div className="product-name">{data.products[0].name}</div>
                                                <div className="product-desc">{data.description}</div>
                                                <Link to={"/detail-produk/"+data.products[0].path} className="btnNine btnNine-default">Beli Langsung</Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="template-grid-half">
                                {promos.map(data=>{
                                    return (
                                        <div className="template-grid-item" key={data.id} >
                                            <div className="template-grid-content">
                                                <img src={FOLDER_MEDIA_PATH+data.products[0].product_colors[0].product_color_images[0].file.name} alt="promoImage" />
                                                <div className="template-grid-desc">
                                                    <div className="product-name">{data.products[0].name}</div>
                                                    <div className="product-desc">{data.description}</div>
                                                    <Link to={"/detail-produk/"+data.products[0].path} className="btnNine btnNine-default">Beli Langsung</Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
  )
}

export default Promo